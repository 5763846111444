import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Hero } from '@outdoorly/hero';
import { Filmstrip } from '@outdoorly/filmstrip';
import { Box, VStack } from '@chakra-ui/layout';
import { Container } from '@outdoorly/container';
import { Highlight } from '@outdoorly/highlight';
import { Text } from '@outdoorly/text';
import { getLinkedObjectHref } from '@outdoorly/util';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { ProductCardWithPreview } from './product-card-with-preview';

const randomId = () => Math.random().toString(36).substring(7);

export const FeatureCarousel = ({
  features = [],
  paginate = true,
  name,
  description,
  isHero,
  aspectRatio,
}) => {
  const id = useMemo(() => randomId(), []);

  if (
    (['HERO_LARGE', 'HERO_MEDIUM', 'HERO_SMALL'].includes(features[0].type) ||
      isHero) &&
    !aspectRatio
  )
    return (
      <Box as="section" maxW="full" backgroundColor="scheme.bgPrimaryInverse">
        <Hero
          contentMaxWidth="7xl"
          content={features}
          id={id}
          autoplay={{ delay: 7000 }}
        />
      </Box>
    );
  if (
    ['HIGHLIGHT_LARGE', 'HIGHLIGHT_SMALL'].includes(features[0].type) ||
    aspectRatio
  ) {
    if (paginate) {
      return (
        <VStack spacing={6}>
          {name && (
            <Text as="h3" fontWeight="500" size="3xl" textAlign="center">
              {name}
            </Text>
          )}
          {description && (
            <Text size="lg" textAlign="center">
              {description}
            </Text>
          )}
          <Highlight content={features} id={id} aspectRatio={aspectRatio} />;
        </VStack>
      );
    } else {
      return (
        <VStack spacing={6}>
          {name && (
            <Text as="h3" fontWeight="500" size="3xl" textAlign="center">
              {name}
            </Text>
          )}
          {description && (
            <Text size="lg" textAlign="center">
              {description}
            </Text>
          )}
          <Wrap justify="center" spacing={6} w="full" px={4} maxW="7xl">
            {features.map((feature, idx) => (
              <Highlight
                content={[feature]}
                as={WrapItem}
                key={idx}
                aspectRatio={aspectRatio}
                flexBasis={feature.type === 'HIGHLIGHT_LARGE' ? 'md' : 'xs'}
              />
            ))}
          </Wrap>
        </VStack>
      );
    }
  }
};

FeatureCarousel.propTypes = {
  aspectRatio: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.array,
  isHero: PropTypes.bool,
  name: PropTypes.string,
  paginate: PropTypes.bool,
};

const ProductCarousel = ({ products = [], name, description }) => {
  const id = useMemo(() => randomId(), []);
  return (
    <Container paddingY={8} position="relative">
      <VStack spacing={6}>
        {name && (
          <Text fontWeight="500" size="3xl">
            {name}
          </Text>
        )}
        {description && <Text size="lg">{description}</Text>}
        {products.length && (
          <Filmstrip
            centered={true}
            loop={true}
            peekWidth={10}
            visibleFrames={[2, 2, 4, 5, 5]}
            marginOverflow={20}
            id={id}
            w="full"
          >
            {products.map((product) => (
              <ProductCardWithPreview
                key={product.id}
                paddingY={2}
                product={product}
              />
            ))}
          </Filmstrip>
        )}
      </VStack>
    </Container>
  );
};

ProductCarousel.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  products: PropTypes.array,
};

export const Carousel = ({ carousel, aspectRatio }) => {
  const type = carousel.elementType;

  if (type === 'Features') {
    if (carousel.featureCarousels.edges.length === 0) return null;
    return (
      <FeatureCarousel
        features={carousel.featureCarousels.edges.map((featureEdge) => {
          const actions = [];

          if (featureEdge.node.feature.linkedObject) {
            actions.push({
              text: featureEdge.node.feature.linkedObject.text,
              href: getLinkedObjectHref(featureEdge.node.feature.linkedObject),
            });
          }

          return {
            ...featureEdge.node.feature,
            smallLogo: featureEdge.node.feature.smallLogo?.fileUrl,
            largeLogo: featureEdge.node.feature.largeLogo?.fileUrl,
            backgroundImage:
              featureEdge.node.feature.backgroundImage?.image?.url,
            mobileImage: featureEdge.node.feature.mobileImage?.image?.url,
            actions,
          };
        })}
        name={carousel.name}
        description={carousel.description}
        paginate={carousel.paginate}
        aspectRatio={aspectRatio}
      />
    );
  }
  if (type === 'Products') {
    return (
      <ProductCarousel
        products={carousel.products.edges.map((edge) => edge.node)}
        name={carousel.name}
        description={carousel.description}
      />
    );
  }
  if (type === 'Empty') {
    return null;
  }
};

Carousel.propTypes = {
  aspectRatio: PropTypes.string,
  carousel: PropTypes.shape({
    description: PropTypes.string,
    elementType: PropTypes.string,
    featureCarousels: PropTypes.shape({
      edges: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func,
      }),
    }),
    name: PropTypes.string,
    paginate: PropTypes.bool,
    products: PropTypes.shape({
      edges: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
  }),
};
